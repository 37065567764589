<template>
    <div class="home container">
        <div class="header-top">
            <div class="text-top">
                use <span>faster</span> <br>
                save time
            </div>
            <div class="text-desc">
                A huge selection of ready-made videos about health and fitness, <br> finance, trading, a service that will help
                save your time!
            </div>
            <div class="btn-basic">
                Join Faster Now!
            </div>
            <img class="abs-img" src="../assets/img.png" alt="">
        </div>
        <div class="not-sure">
            <div class="title">
                Not sure to start?
            </div>
            <div class="not-sure-desc">
                Templates offer to save your time and create a huge number of videos without the participation of a designer
            </div>
            <div class="items">
                <div class="item">
                    <div class="title-item">
                        Chose templates
                    </div>
                    <div class="near">
                        <div class="desc">
                            Choose your favorite template from different categories
                        </div>
                        <img class="img" src="../assets/arrow.svg">
                    </div>
                </div>
                <div class="item">
                    <div class="title-item">
                        Edit
                    </div>
                    <div class="near">
                        <div class="desc">
                            Change text, pictures or according to your requests in your personal account
                        </div>
                        <img class="img" src="../assets/arrow.svg">
                    </div>
                </div>
                <div class="item">

                    <div class="title-item">
                        Take
                    </div>
                    <div class="near">
                        <div class="desc">
                            Get ready-made files on Google Drive, average render time up to 3 minutes!
                        </div>
                        <img class="img" src="../assets/arrow.svg">
                    </div>
                </div>
                <div class="item">
                    <div class="title-item">
                        Use
                    </div>
                    <div class="near">
                        <div class="desc">
                            Use video to your advantage
                        </div>
                        <img class="img" src="../assets/arrow.svg">
                    </div>
                </div>
            </div>
        </div>
        <div class="useful-for">

            <div class="item">
                <div class="text">
                    <div class="title-item">
                        Who is it useful for?
                    </div>
                    <div class="desc">
                        Advertising agency and arbitration teams, startups, business creators and large brands, designers who want
                        to optimize the work process and perform more creative work, YouTube creators or influencers.
                    </div>
                    <a href="">
                        <div class="btn">See more <img class="img-arrow" src="../assets/arrow.svg"></div>
                    </a>
                </div>
                <div>
                    <img class="img" src="../assets/img_2.svg" alt="">
                </div>
            </div>

            <div class="item">

                <div>
                    <img class="img" src="../assets/img_1.svg" alt="">
                </div>
                <div class="text">
                    <div class="title-item">
                        By saving time you save money
                    </div>
                    <div class="desc">
                        The average number of edits takes a designer 15 minutes, but what if there are no edits and there is a
                        ready-made video
                    </div>
                    <a href="">
                        <div class="btn">See more <img class="img-arrow" src="../assets/arrow.svg"></div>
                    </a>
                </div>

            </div>
        </div>
        <div class="price">
            <div class="title-main">
                Price
            </div>
            <div class="items">
                <div class="item" v-for="item in vipList" :key="item.id">
                    <div class="title">
                        {{ item.name }}
                    </div>
                    <div class="price-n">
                        {{ parseFloat(item.price).toFixed(0) }} 	€
                    </div>
                    <div class="desc">
                        {{ item.description }}
                    </div>
                    <ul>
                        <li>~350 Full templates</li>
                        <li>Customizable video</li>
                        <li>Fast render</li>
                        <li>20+ category</li>
                        <li>Take done video Google Drive</li>
                        <li>No Credit Card Needed</li>
                    </ul>
                    <div class="link-left" @click="pay(item.id)">Pay <img class="img-arrow" src="../assets/arrow.svg"></div>
                </div>
            </div>
            <a href="">
                <div class="back-to">
                    <div class="back">
                        Back to Top <img class="img-arrow" src="../assets/coolicon.svg">
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import httpService from "@/services/http.service.ts";
import {useStateStore} from "@/store/state.ts";
import router from "@/router";
import * as notification from "@kyvg/vue3-notification";
import {useRoute} from "vue-router";
const stateStore = useStateStore();
const vipList = ref(null);
const route = useRoute()

onMounted(async () => {
    const vipResponse = await httpService.get('vip-status')
    vipList.value = vipResponse.data.Vip;

    if(route.query.verification) {
        notification.notify({
            title: "Email confirmed successfully",
            type: "success"
        });
    }


})

async function pay(id) {
    if(!stateStore.isAuthenticated) {
        router.push('/login')
    }
    const res = await httpService.get('payments/create?id='+id+'&type=package');
    if(res) {
        window.location.href = res.data?.redirect_url;
    }
}

</script>

<style scoped lang="scss">
a {
    text-decoration: none;
}

.header-top {
    position: relative;
    color: #FAFAF9;
    margin-top: 100px;
    margin-bottom: 100px;

    .abs-img {
        top: 50px;
        right: 0;
        height: 800px;
        position: absolute;
    }

    .text-top {
        font-family: 'Abel', sans-serif;
        font-size: 150px;
        text-transform: uppercase;

        span {
            color: #EF8964;
        }
    }

    .text-desc {
        margin-bottom: 50px;
        font-size: 16px;
        font-weight: 200;
        color: rgba(239, 237, 232, 0.65);
    }
}

.not-sure {
    margin-top: 350px;
    display: flex;
    flex-direction: column;

    .not-sure-desc {
        color: #EFEDE8A6;
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 53px;
    }

    .items {
        display: flex;
        justify-content: space-between;

        .item {
            width: 23%;
            background: #323443;
            border-radius: 12px;
            height: 190px;
            display: grid;
            padding: 25px;
            align-content: space-around;
            color: #fff;

            .title-item {
                padding-bottom: 10px;
                font-size: 16px;
                color: #FAFAF9;
            }

            .near {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .img {
                height: 20px;
            }


            .desc {
                color: rgba(239, 237, 232, 0.65);
                line-height: 24px;
                font-size: 14px;
            }

        }
    }
}

.useful-for {
    margin-top: 189px;
    display: flex;
    flex-direction: column;

    .item {
        border-radius: 12px;
        padding: 25px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {

            .title-item {
                line-height: 70px;
                font-size: 50px;
                width: 591px;
                color: white;
                margin-bottom: 48px;
            }

            .desc {
                line-height: 24px;
                font-size: 14px;
                color: rgba(239, 237, 232, 0.65);
                width: 480px;
                margin-bottom: 58px;
            }
        }

        .img {
            height: 510px;
        }

        .btn {
            display: flex;
            align-items: center;
            color: #0070C9;
        }

        .img-arrow {
            margin-left: 7px;
            height: 20px;
        }

        .near {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }
}

.price {

    .title-main {
        line-height: 70px;
        font-size: 50px;
        margin-top: 199px;
        color: white;
    }

    .items {
        display: flex;
        justify-content: space-around;
        margin-top: 94px;
        flex-wrap: wrap;

        .item {
            display: flex;
            margin-bottom: 20px;
            width: 538px;
            height: 640px;
            background: #232532;
            padding: 0 68px;
            align-items: center;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            ul {
                margin-top: 50px;
                margin-left: -60px;

                li {
                    line-height: 24px;
                    font-size: 18px;
                    color: rgba(239, 237, 232, 0.65);
                    letter-spacing: 0.5px;
                }
            }

            .title {
                line-height: 50px;
                font-size: 40px;
                text-transform: capitalize;
            }

            .price-n {
                font-size: 28px;
                font-weight: bold;
            }

            .desc {
                margin-top: 49px;
                line-height: 24px;
                width: 402px;
                font-size: 18px;
                color: rgba(239, 237, 232, 0.65);
            }


            .link-left {
                display: flex;
                color: #0070C9;
                margin-top: 124px;
                line-height: 26px;
                font-size: 16px;
                cursor: pointer;
                align-items: center;
                margin-right: auto;
            }

            .img-arrow {
                margin-left: 7px;
                height: 20px;
            }

            .link-right {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #0070C9;
                width: 150px;
                height: 30px;
                border-radius: 10px;
                line-height: 22px;
                font-size: 16px;
                color: white;
                margin-top: 103px;
                margin-left: -63%;
            }

        }

    }
}

.back-to {
    display: flex;
    justify-content: flex-end;

    .back {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: #FAFAF9;
        width: 150px;
        height: 38px;
        line-height: 24px;
        font-size: 18px;
        font-weight: bold;
        color: #303030;
        margin-top: 38px;
    }
}


</style>
