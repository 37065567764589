<template>
    <div class="profile-info container" v-if="user">
        <div class="left">
            <div class="logo">
                <img :src="user.avatar" alt="">
            </div>
            <div class="user">
                <div class="name">{{user.name}}</div>
                <div class="subname">{{user.email}}</div>
            </div>
        </div>
        <div class="right">
            <div class="status">
                {{ user.vip_status.name }}
            </div>
            <div class="render">
                render {{ user.render }}
            </div>
            <div class="remaining" v-if="user.vip_until">
                Status until: {{ format(user.vip_until, "dd-MM-yyyy") }}
            </div>
        </div>
    </div>
    <v-card class="list container center" v-if="!user.tasks">
        {{user}}
    </v-card>
    <v-card class="list container" v-if="user.tasks && user.tasks.length > 0">
        <v-card-title>
            Render List
        </v-card-title>
        <div class="active-tasks" v-if="user && user.tasks">
            <div class="item" :class="item.at_work ? 'at-work' : 'done'" v-for="item of user.tasks" :key="item.nr_project">
                <div class="name">
                    {{ item.title }} #{{ item.nr_project_task}}{{format(item.created_at, "ddMM")}}{{item.nr_project}}
                    <span>
                        Last render:
                        {{ format(item.created_at, "HH:mm (dd.MM)") }}
                    </span>
                </div>

                <a :href="item.link_project" target="_blank" class="status" v-if="item.link_project">
                    {{ item.at_work ? 'Progress' : 'Done' }}
                    <v-icon v-if="!item.at_work"
                            end
                            icon="mdi-cloud-download"
                    ></v-icon>
                </a>

                <div class="status" v-if="!item.link_project">
                    {{ item.at_work ? 'Progress' : 'Done' }}
                </div>

            </div>
            <v-alert v-if="showErrMsg" type="error">Error, Cant create render</v-alert>
            <v-alert v-if="showSuccessMsg" type="success">Success, creative will be rendered soon</v-alert>
        </div>
    </v-card>


</template>
<script setup>
import {onMounted, ref} from "vue";
import httpService from "@/services/http.service.ts";
import {VCard, VCardTitle, VIcon} from "vuetify/components"
import {format} from "date-fns";


const user = ref('');
onMounted(async () => {
    const res = await httpService.get(`user?include-tasks=true`)
    user.value = res.data ? res.data.data[0] : res.response.data.verification_message;
})
</script>

<style scoped lang="scss">
.center {
    text-align: center;
    padding: 20px;
    font-size: 20px;
}
.profile-info {
    background: url("../assets/profile.png") !important;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    .left {
        display: flex;
        align-items: center;
        .logo {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            margin-right: 20px;
            img {
                border-radius: 50%;
                width: 100%;
                height: 100%;
            }
        }
        .name{
            font-size: 40px;
        }
        .subname {
            font-size: 20px;
        }
    }
    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        .status {
            color: #EF8964;
            text-transform: capitalize;
            font-size: 25px;
        }
        .render {
            background: #E6533C;
            padding: 10px 25px;
            margin: 10px 0;
            font-size: 30px;
            text-transform: uppercase;
            text-align: center;
            border-radius: 10px;
        }
        .remaining {
            font-size: 15px;
            color: rgba(239, 237, 232, 0.65)
        }
    }
}

.list {
    margin-top: 40px;
}

.active-tasks {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .item {
        background: #2c2c2c;
        padding: 15px;
        margin-bottom: 10px;
        justify-content: space-between;
        display: flex;
        align-items: center;

        .name {
            display: flex;
            flex-direction: column;

            span {
                font-size: 12px;
            }
        }

        a {
            text-decoration: none;
        }

        &.at-work {
            .status {
                color: #F2C94C;
            }
        }

        &.done {
            .status {
                color: #27AE60;
            }
        }
    }
}
</style>
