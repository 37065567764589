<template>
    <header-component></header-component>
    <main>
        <router-view></router-view>
    </main>
    <footer-component></footer-component>
    <notifications position="top right" />
</template>

<script setup>

import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import {Notifications} from "@kyvg/vue3-notification";

document.title = 'Faster';
</script>

<style>

.vue-notification-group {
    top: 20px !important;
}

.vue-notification .notification-title {
    color: black !important;
}

.title {
    color: #FAFAF9;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 15px;
}

.desc-title {
    margin-bottom: 30px;
    font-size: 16px;
    color: #EFEDE8;
}
</style>
